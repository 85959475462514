import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { DialogsService } from './dialogs/dialogs.service';
import { JwtService } from './jwt.service';
import { Router } from '@angular/router';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserAuditV2Service } from './reports/user-audit-updated/user-audit-v2.service';

/**
 * class HttpLogInterceptor
 */
@Injectable()
export class HttpLogInterceptor implements HttpInterceptor {

  tmout;
  /** constructor of HttpLogInterceptor */
  constructor(private router: Router, private dialogService: DialogsService, private auth: JwtService, private snackBar: MatSnackBar,
    private userAuditV2Service: UserAuditV2Service)  {}

  /**
   * HttpLogInterceptor intercept method
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

/*     const timeoutValue = req.headers.get('timeout');
    const timeoutValueNumeric = timeoutValue ? Number(timeoutValue) : 240000;
    console.log(timeoutValue, timeoutValueNumeric); */
    return next.handle(req)
      .pipe(
     /*    timeout(timeoutValueNumeric), */
        catchError(httpError => {
          if (httpError) {
            this.dialogService.closeWaitingSpinner();

            if (this.auth.loggedIn === false) {
              const ap = {
                comment: 'session expired'
              };
              // this.userAuditV2Service.createAuditRecord('Login', 'insert', JSON.stringify(ap));
              if (httpError.error.status === 401 || httpError.status === 401) {
                this.openSnackBar(['-' + httpError.error.error]);
              } else {
                this.openSnackBar(['-' + 'Your session was expired. Re-login, please.']);
              }

              this.router.navigate(['/login']);
              return throwError(httpError);
            } else {
              const urlstr = httpError.url ? httpError.url : '';
              if (urlstr.indexOf('smppClient') > -1) {
                return throwError(httpError);
              } else
              if (urlstr.indexOf('getClients') > -1) {
                return throwError('billingError');
              } else
              if (urlstr.indexOf('exportCDRs') > -1 && httpError.status === 400) {
                return throwError('cdrsError');
              } else
              if (urlstr.indexOf('costsUpdater/getErrors') > -1) {
                return throwError(httpError);
              } else
              if (urlstr.indexOf('messageCounts/getMetaByCriteria') > -1) {
                return throwError(httpError);
              } else
              if (httpError.error.status === 401 || httpError.status === 401) {
                if (urlstr.indexOf('auth/refresh') > -1 /* && httpError?.error?.error.indexOf('no data') > 0 */) {
                   // ('no data') got when refresh method is called several times in short time interval
                  this.router.navigate(['/']);
                } else {
                  this.auth.refreshToken().toPromise().then(res => {
                    this.tmout = setTimeout(() => {
                      next.handle(req);
                      this.clearTimeOut(this.tmout);
                      // this.router.navigate([/* '/panel' */urlstr]);
                    }, 500);
                  }).catch(err => {
                    if (err.error.status === 401 || err.status === 401) {
                      this.router.navigate(['/']);
                    }
                  });
                }

                // return throwError(httpError);
              } else {
                const er = (httpError.error && httpError.error.status) ?
                  ('error status: ' + httpError.error.status + ', message: ' +  httpError.error.message) :
                  ('error status: ' + httpError.status + ', message: ' +  httpError.message);
                // this.openSnackBar(['-' + er]);
                if (httpError.error.status === 0 || httpError.status === 0) {
                  const ap = {
                    comment: 'http error',
                    error: er
                  };
                  this.userAuditV2Service.createAuditRecord('Login', 'insert', JSON.stringify(ap));
                  this.router.navigate(['/']);
                }

  /*               if (httpError.error.status) {
                  this.dialogService.openInfoMessageDialog('error status: ' + httpError.error.status
                  + ', message: ' +  httpError.error.message, 'Error', 'HttpError');
                } else {
                  this.dialogService.openInfoMessageDialog('error status: ' + httpError.status
                  + ', message: ' +  httpError.message, 'Error', 'HttpError');
                } */
                return throwError(httpError);
              }

            }
          }


        })
      );
  }

  clearTimeOut(tm) {
    clearTimeout(tm);
  }

  addAuthHeader(request) {
    const authHeader = 'Bearer ' + localStorage.getItem('access_token');
    if (authHeader) {
        return request.clone({
            setHeaders: {
                Authorization: authHeader
            }
        });
    }
    return request;
}

  openSnackBar(message: Array<string>) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: message
    });
  }
}



/*
,
    catchError((error: HttpErrorResponse) => {
      let data = {};
      data = {
          reason: error && error.error.reason ? error.error.reason : '',
          status: error.status
      };
      console.log(data);
      return throwError(error);
    }); */
