import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

/**
* Component decorator
*/
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  /**
  * Constructor of class PageNotFoundComponent
  */  
  constructor(private router:Router) { }

  /**
  * Actions that would be executed on initialization of component
  */  
  ngOnInit() {
  }

  /**
  * Navigate to Homepage method
  */  
  goToHomePage() {  
    this.router.navigate(['../']);
  }

}
