import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable, throwError, EMPTY, from } from 'rxjs';
import { catchError, finalize, timeout } from 'rxjs/operators';

/**
 * class RequestCountHttpInterceptor
 */
@Injectable()
export class RequestCountHttpInterceptor implements HttpInterceptor {
  pendingRequestsCount = 0;
  refreshReq           = null;
  interval;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler)  {
    if (req.url.includes('auth/refresh') && !this.refreshReq) {
      this.refreshReq = req;

      return new Promise<any>(resolve => {
        this.interval = setInterval(() => {
          if (!this.pendingRequestsCount) {
            clearInterval(this.interval);

            return resolve(next
              .handle(this.refreshReq)
              .pipe(finalize(() => {
                this.refreshReq = null;
              }))
              .toPromise()
            );
          }
        }, 1000);
      });
    } else if (req.url.includes('auth/refresh') && this.refreshReq) {
        return EMPTY.toPromise();
    }

    this.pendingRequestsCount++;

    return next
      .handle(req)
      .pipe(finalize(() => this.pendingRequestsCount--))
      .toPromise();
  }
}
