import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { UserService } from 'src/app/user.service';
import { Router } from '@angular/router';

/** Adress of API Server */
const backendEndpoint = environment.backendEndpoint;
const ipEndpoint = environment.ipEndpoint;

@Injectable({
  providedIn: 'root'
})
export class UserAuditV2Service {


  /** base url for requests */
  baseUrl  = /* 'http://localhost:3030/api/v1'; // */'/api/v1';
  // http://six5-portal-svcs.traefik.aws.submitmsg.com/api/v1/audit

  endUrl = '';
  private userIP: string;
  private userID = 99999;
  private backendURL: string;


  constructor(private httpClient: HttpClient, private userService: UserService, private router: Router) {
    this.endUrl = backendEndpoint + this.baseUrl;
  }

  setUsersIP(ip) {
    this.userIP = ip;
    localStorage.setItem('user_ip', ip);
  }

  setUsersID(id) {
    this.userID = id;
  }

  getUserID() {
    return this.userID;
  }

  setBackendUrl(val) {
    this.backendURL = val;
  }

  getBackendUrl() {
    return this.backendURL;
  }

  readConfig() {
    return this.httpClient.get('/config.json?_cache_buster=' + new Date().getTime());
  }

/*   getIPAddress() {
    this.httpClient.get('https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json').toPromise().then((res:any)=>{
      this.setUsersIP(res.ip);
    });
  } */
  /* http://dev.smpp-portal-nextgen.traefik.aws.submitmsg.com/ip/?format=json */
  getIPAddress() {
    this.httpClient.get(ipEndpoint + '/?format=json').toPromise().then((res: any)=>{
      this.setUsersIP(res.ip);
    });
  }

  getIPFromAmazon() {
    fetch(ipEndpoint).then(res => res.text()).then(data => this.setUsersIP(data));
  }

  getIPCloudfare() {
    fetch(ipEndpoint).then(res => res.text()).then(data => {
      const ip = data.indexOf('ip');
      const str = data.substring(ip + 3);
      this.setUsersIP(str.substring(0, str.indexOf('\n')));
    });
  }

  async createAuditRecord(auditName, action, actionParams) {

      const params = {
        user_id: this.userID,
        ip_address: this.userIP ? this.userIP : (localStorage.getItem('user_ip') ? localStorage.getItem('user_ip') : '0.0.0.0'),
        audit_name: auditName,
        action,
        action_params: actionParams
      };

      this.httpClient.post<any>(this.endUrl + '/users/'+ params.user_id + '/audit',
        params, { observe: 'response' }).toPromise().then(() => {});

  }



}
