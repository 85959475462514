import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from './../environments/environment';

import { tap } from 'rxjs/operators';
import { UserData } from './userData';
import { AppGlobal } from './app.global';

/** Adress of API Server */
const endpoint = environment.Endpoint;
const backendEndpoint = environment.backendEndpoint;
/* const backendEndpoint2 = environment.backendEndpoint2; */
/** Service what returns user data from backend API */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  userData = new UserData();
  /** base url for requests */
  baseUrl    = '/api/user';
  baseUrl2   = /* 'http://localhost:3030/api/v1/user';  // */ '/api/v1/user';

  endUrl     = '';

  userDataObservable: Observable<any>;
  private userDataSubject = new BehaviorSubject<UserData>(null);

  /** constructor of class UserService */
  constructor() {
    this.userDataObservable = this.userDataSubject.asObservable();
    this.userData.client_name = '';
    this.userData.client_id = -1;
  }

  getLoggedInEndUrl() {
    /* const found = localStorage.getItem('loggedInWithVer');
    if (found) {
      return (JSON.parse(found) === 1 ? endpoint + this.baseUrl :  */return backendEndpoint + this.baseUrl2;/* );
    } else {
      return this.endUrl;
    } */
  }

  getLoggedInSlash() {
    /* const found = localStorage.getItem('loggedInWithVer');
    if (found && JSON.parse(found) === 2) { */
      return '/';
    /* }

    return ''; */
  }

  setUserData(data) {
    this.userData.client_id = data.client_id;
    this.userData.client_name = data.client_name;

    this.userDataSubject.next(this.userData);
  }

  getUserDataObservable(): Observable<UserData> {
    return this.userDataObservable;
  }

  getUserData() {
    return this.userData;
  }

}
