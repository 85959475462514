import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobal {
  static CONFIG = {};


  userVer = 0;
  useNewRouter = /* false; // */ true;

  constructor() {}
}
