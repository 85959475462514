import { Component      } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppGlobal      } from './app.global';
/** Decorator of root component */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent  {
  /** Title of application */
  title = 'rhythm01';

/** Constructor of class AppComponent */

constructor(private activatedRoute: ActivatedRoute, private app: AppGlobal) {
  this.activatedRoute.queryParams.subscribe(params => {
    if (params['ver'] !== undefined) {
      this.app.userVer = parseInt(params['ver'], 10);
    }
  });
}


}
