/**
 * Environment variable for production mode
 */
export const environment = {
  production: true,
  smsPlatformApi:  '/smsplatformapi',
  cdrextractor:  '/cdrextractor',
  /**
   * Adress of API Server
   */
  globalVer : 1,
  Endpoint: '/portal',
  RULES_BASE_URL: '/rulesbaseurl',
  TRADINGRULES_BASE_URL: '/tradingrulesbaseurl',
  backendEndpoint: '/backend',
  pivsEndpoint: '/pivs',
  ipEndpoint: 'https://www.cloudflare.com/cdn-cgi/trace:443',
  /** Session expired period (sec) */
  Expired: 7200,
  /**
   * user inactive idle time (sec)
   */
  idle: 300,
  /**
   * user inactive timeout (sec)
   */
  timeout: 300,
  /**
   *  ping is used if we want to perform some action periodically every n-minutes in lifecycle of timer (from start timer to timeout). (sec)
   */
  ping: 60,
  /** The response type of the HTTP Logging request build_time: 'Wed Feb 14 07:13:01 2024'*/
  httpResponseType : 'json' as 'json',
  version: 'DEV',
  build_time: 'Wed Feb 14 07:13:01 2024',
  build_commit: '6bdac71'
};
