import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WaitingService {

  private spinnerDialodId;

  constructor() { }

  setSpinnerId(id) {
    this.spinnerDialodId = id;
  }

  getSpinnerId() {
    return this.spinnerDialodId;
  }

}
