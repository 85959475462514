import { Injectable } from '@angular/core';
import moment from 'moment'; // using @types/moment
import { WaitingService } from '../waiting.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  constructor(private dialog: MatDialog, private waitingService: WaitingService) { }

  uniqCode() {
    return  moment().format('x').toString();
  }

  async openInfoMessageDialog(mess: string, type: string, tabCode: string) {
    const { InfoMessageComponent } = await import(
      /* webpackPrefetch: true */
      './info-message/info-message.component'
    );

    return this.dialog.open(InfoMessageComponent, {
      width: '420px',
      disableClose: false,
      hasBackdrop: false,
      id: 'info' + tabCode + this.uniqCode(),
      data: {
        infoMessage: mess,
        type
      }
    });
  }

  closeWaitingSpinner() {
    // return this.dialog.closeAll();
    if (this.dialog.getDialogById(this.waitingService.getSpinnerId())) {
      this.dialog.getDialogById(this.waitingService.getSpinnerId()).close() ;
    }

  }

  closeDialogByID(id: string, result?) {
    if (this.dialog.getDialogById(id)) {
      this.dialog.getDialogById(id).close(result ? result : undefined ) ;
    }
  }
}
