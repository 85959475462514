
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { environment } from './../environments/environment';
import { AppGlobal   } from './app.global';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PersistenceModule } from 'angular-persistence';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentUtcDateAdapter } from './moment.utc';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HttpLogInterceptor } from './http-log-interceptor';
import { RequestCountHttpInterceptor } from './request-count-http-interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './navigation/cache-route-reuse.strategy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule,  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

/** Get environment variable: path to API server  */
const endpoint = environment.Endpoint;
const backendEndpoint = environment.backendEndpoint;
/* const backendEndpoint2 = environment.backendEndpoint2; */
/** get access_token itm from local storage  */
/* export function jwtTokenGetter() {
  return  localStorage.getItem('access_token');
} */

@NgModule({
  declarations: [
    AppComponent, PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => 'Bearer ' + localStorage.getItem('access_token'),
        allowedDomains: ['localhost:3030', endpoint, backendEndpoint],
        disallowedRoutes: ['http://localhost:3030/api/v1/auth/slogin', backendEndpoint + '/v1/auth/slogin',
        'http://localhost:3030/api/v1/auth/refresh', backendEndpoint + '/v1/auth/refresh'],
        headerName: 'Authorization',//'Authorization': `Bearer ${auth_token}`
        authScheme: ''
      }
    }),
    BrowserAnimationsModule,
    MatDialogModule, MatSnackBarModule, MatTooltipModule, MatSelectModule, MatFormFieldModule,
    MatListModule, MatInputModule, MatNativeDateModule, MatDatepickerModule, MatAutocompleteModule,
    MatMenuModule, PersistenceModule, VirtualScrollerModule, MatIconModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {
      provide: MatDialogRef,
      useValue: {}
    },
    FormsModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    AppGlobal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLogInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCountHttpInterceptor,
      multi: true,
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

