import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { tap } from 'rxjs/operators';
import moment from 'moment';

/** Adress of API Server */
const backendEndpoint = environment.backendEndpoint;

/* const backendEndpoint2 = environment.backendEndpoint2; */
/** Session expired period */
const expired = environment.Expired;

/** Decorator that allows service using anyware */
@Injectable({
  providedIn: 'root'
})

export class JwtService {

  endUrl     = '';
  baseUrl2 = /* 'http://localhost:3030/api/v1/auth';  // */ '/api/v1/auth';

  /**  Constructor of class JwtService  */
  constructor(private httpClient: HttpClient) {
    this.endUrl     =  backendEndpoint + this.baseUrl2;
  }


  login(smpp_user: string, smpp_pass: string, access_key: string) {
    return this.httpClient.post(this.endUrl + '/slogin', {smpp_user, smpp_pass, access_key})
    .pipe(tap((res: any) => {

      const expiresAt = moment().add(expired, 'second'); // moment().add(rememberMe ? 1209600 : expired, 'second');

      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('refresh_token', res.refresh_token);
      localStorage.setItem('expires_at',  JSON.stringify(expiresAt.valueOf()));
      // localStorage.setItem('user_mail', email);
    }));
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');

    return this.httpClient.post(this.endUrl + '/refresh', {refresh_token: refreshToken})
    .pipe(tap((res: any) => {

      const expiresAt = moment().add(expired, 'second'); // moment().add(rememberMe ? 1209600 : expired, 'second');

      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('refresh_token', res.refresh_token);
      localStorage.setItem('expires_at',  JSON.stringify(expiresAt.valueOf()));
      // localStorage.setItem('user_mail', email);
    }));

  }

  /** Return timestamp of token expiration  */
  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  /** Logout method. Free localStorage  */
  logout() {
    // console.log('access_token:', localStorage.getItem('access_token'), '. Logged out');
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_ip');
    // localStorage.removeItem('user_mail');
  }

  /** Property returns true if user is logged in and his token isn't expired  */
  public get loggedIn(): boolean {
    return (localStorage.getItem('access_token') !==  null) && (moment().isBefore(this.getExpiration()));
  }

  /* public get userEMail(): string {
    return localStorage.getItem('user_mail');
  } */
}
